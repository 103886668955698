/* * {
    box-sizing: border-box;
  }
  
  body {
    max-width: 50rem;
    margin: 0 auto;
    padding: 2rem;
  } */
  
  
  .carousel__nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .carousel {
    position: relative;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    z-index: 0;

    /* LR */
    background-color: #333;

    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    text-decoration: none;
    outline: none;
    /* overflow: hidden; */
    /* height: 100%;
    width: 100%; */
  }
  
  .carousel:focus {
    /* outline: 0.25rem solid hsl(220, 60%, 60%);
    outline-offset: 0.25rem; */
  }
  
  .wrapper {
    /* position: relative; */

    /* LR */
    position: fixed;
    /* top:50%; */
    top:0;
    /* left: 50%; */
    left: 0;
    /* transform: translate(-50%, -50%); */
    width: 100vw;
    height: 100vh;
    z-index: 99;

    background-color: #333;

    box-sizing: border-box;
    
  }
  
  .wrapper::before,
  .wrapper::after {
    --size: 2rem;
    content: '';
    display: block;
    position: absolute;
    width: var(--size);
    height: 100%;
    top: 0;
    left: 0.5rem;
    padding: 0.25rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round"><path d="M7 2 L2 5 L7 8" /></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: #fff;
    z-index: 10;
    pointer-events: none;
  }
  
  .wrapper::after {
    left: auto;
    right: 0.5rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round"><path d="M2 2 L7 5 L2 8" /></svg>');
  }

  .close-wrapper{
    color: red;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    z-index: 1;
    padding-top: 15px;
  }
  
  .slide {
    position: relative;
    flex: 1 0 100%;

    /* scroll-snap-align: center; */

    /* LR */
    display: flex;
    justify-content: center;
    margin: auto;
    outline: none;
  }
  
  .slide a {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    width: 3rem;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 0;

    /* LR */
    /* background-color: beige; */
    text-decoration: none;

    outline: none;
    margin:0;
    border:0; 
    padding:0;
    
  }

  .slide a:focus,
  .slide a:visited,
  .slide a:active {
      outline: none;
      text-decoration: none;
      color: inherit;
  }
  
  .slide a:last-of-type {
    top: 0;
    right: 0;
    left:auto;
  }

  .carousel img {
    display: block;
    /* max-width: 100%; 
    max-height: 100%; */
    /* width: 100%;
    height: 100%; */
    /* object-fit: cover; */

    /* LR */
    /* object-fit: scale-down; */
    /* margin:auto; */
    

  }

  .carousel__nav{
      position:absolute;
      bottom:0;
      margin: auto;
      width: 100%;
      /* height: 100%; */
  }
  .carousel__nav ul {
    margin-top: -3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .carousel__nav a {
    display: grid;
    place-items: center;
    width: 2.75rem;
    height: 2.75rem;
    color: white;
    text-decoration: none;
    text-shadow: 1px 1px 0 hsla(220, 20%, 20%, 0.5);
  }
  
  .carousel__nav a:hover {
    
  }

.home-section-content{
    margin: 0px auto 0px auto;
    padding: 0px;
    max-width: 1920px;
    width: 100%;
}

.home-center{
    display:flex; 
    flex-flow:row wrap;
    font-family:'Raleway';
    font-size:1.2em; 
    /* margin-top:30px; */
}

.home-center-intro{
    margin-top:0px;
}

.heromsg-container{
    position:absolute;
    width:auto;
    height:auto;
    padding:0px 20px;
    bottom:20px;
    left:calc(0vw + 100px);
    /* background: rgba(42, 182, 204, 0.3) */
    background: rgba(29, 128, 145, 0.3)
}

.heromsg {
    font-family: 'Oswald';
    font-size: 3em;
    text-align: center;
    line-height: 1.2em;
    color:#fff;
}


@media (max-width:800px) {

    .heromsg-container{
        background: none;
        position: relative;
        left:0;
    }

    .heromsg {
        font-size: 2em;
        /* color: #2ab6cc; */
        color:#249fb2;
    }

    .heromsg ~ div{
        background: none;
        position: relative;
    }
}


@media (max-width:600px) {
    .heromsg {font-size: 1.6em;}
}

header{

    width:100%;
    background-color: #1f1f1f;
    padding:0px;
    margin:0px;
    position: -webkit-sticky;
    position: sticky;
    top:0;
    font-family: Oswald;
    font-size: 1.1rem;

    padding: 8px 20px;
    z-index: 1;
}

header > .container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 8px 20px;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr  ;
    grid-template-rows: 1fr ;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 1rem 1.5rem; */
    padding: 0px;
    margin: auto 0px;
    /* height: 100%; */

    height: 90px;
} 


.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

.nav-item:focus{
    outline:none;
}
.nav-item:focus-within{
    outline:none;
}

.header-menu-title {
    color: #f7f7f7;
    display: inline;
    padding: 0px 20px;
    margin-top: auto;
    margin-bottom: auto;
    min-height: 26px;
    min-width: auto;
    white-space: nowrap;
    font-size: 1.4rem;
}

/* experiment
.nav-item > a,
.nav-item > span{
    position: relative;
}
.nav-item > a::before,
.nav-item > span::before {
    content: '';
    display: block;
    height: 5px;
    background-color: #f7f7f7;
  
    position: absolute;
    top: 0;
    width: 0%;
  
    transition: all ease-in-out 250ms;
  }

.nav-item >  a:hover::before,
.nav-item >  span:hover::before {
    width: 100%;
    
}
*/

#book-now{
    border:0px;
    border-radius:5px;
    padding:0px;
    width: 100px; 
    height:60px; 
    background-color:orange;
    background-color:#ea9b42;
    color: #fff;
    text-align:middle;
    /* font-weight: bold; */
    font-family: inherit;
    font-size: 1.1rem;;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}

.nav-submenu {
    
    visibility: hidden;
    display: flex;  
    justify-content: space-between;  
    flex-direction: column;  
    vertical-align: middle;
    background-color: #1f1f1f;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 1rem;
    margin-left: -2rem;
    /* left: 0; */
    padding: 10px  ;
    
}

.nav-submenu.and-more {
    margin-left: -4rem;
}

.nav-submenu > .header-menu-title {
    padding: 10px 10px 10px 10px;
}
.nav-submenu > .header-menu-title:last-child {
    padding: 10px 10px 10px 10px;
}

.hamburger {
    display: none;
}
.no-ham{ display: block}
.yes-ham{ display: none}

.bar {
    display: block;
    /* width: 25px; */
    width: 50px;
    /* height: 3px; */
    height: 4px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
    background-color: orange;
}


@media (min-width:1024px) {
    .nav-item:hover  > .nav-submenu {

        top: 78px;
        visibility: visible; 
        opacity: 1;
        display: flex;  
        justify-content: space-between;  
        flex-direction: column;  
    } 
    
    .header-menu-title {
        font-size: 1rem;
    }
    
}


@media (min-width:1280px) {
    .header-menu-title {
        font-size: 1.4rem;
    }
}

@media (max-width:1023px) {
    
    .header-menu-title {
        font-size: 1rem;
    }
    /* header ul {
        position: fixed;
        left:  -100%;
        top: 5rem;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
        justify-content: center;
            align-items:center;
            
    } */

    .nav-menu {
        position: fixed;
        left:  -100%;
        /* left: 0; */
        /* top: 5rem; */
        top: 105px;
        margin: 0px;
        padding: 20px 0px 20px 0px;
        flex-direction: column;
        background-color: #fff;
        background-color: #1f1f1f;
        width: 100%;
        /* border-radius: 10px; */
        text-align: center;
        transition: 0.3s;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
        justify-content: flex-start;
            align-items:flex-start;
            /* coprire tutto con HM? */
            height: 90vh; 

            /* overflow-y: auto; 
            height: 100%; */
        cursor: pointer;   
    }
    
    .nav-menu{
        cursor: pointer;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        
    }
    .no-ham{ display: none}
    .yes-ham{ display: block}

    #my-check:checked ~ .nav-menu{
        left: 0;
    }
    
    #my-check:checked ~ .hamburger .bar:nth-child(2) {
        opacity: 0;
    }

    #my-check:checked ~ .hamburger .bar:nth-child(1) {
        -webkit-transform: translateY(10px) rotate(45deg);
        transform: translateY(10px) rotate(45deg);
    }

    #my-check:checked ~ .hamburger .bar:nth-child(3) {
        -webkit-transform: translateY(-10px) rotate(-45deg);
        transform: translateY(-10px) rotate(-45deg);
    }

    .nav-submenu {
    
        display: flex;  
        justify-content: space-between;  
        flex-direction: column;  
        vertical-align: middle;
        background-color: #1f1f1f;
        opacity: 0;
        min-width: 5rem;
        transition: all 0.5s ease;
        margin-top: 1rem;
        /* margin-left: -1rem; */
        
        /* left: 0; */
        padding: 10px  ;

        display: none;
        position: relative;
        margin: 0;
        padding: 0;
        top: 0;

        margin-left: -2rem;
        
    }
    .nav-submenu.and-more {
        margin-left: -2rem;
    } 

    .nav-item:hover  > span ,
    .nav-item:focus  > span {

        display: flex;
    } 

    /* .nav-item:hover  > .nav-submenu , */
    .nav-item:focus  > .nav-submenu,
    .nav-item:focus-within  > .nav-submenu {

        top: 0px;
        display: flex;
        justify-content: flex-start; 
        align-items: flex-start;
        visibility: visible; 
        opacity: 1;
        padding: 0px 20px 0px 50px;
    } 

    

    .header-menu-title {
        padding: 10px 10px 10px 10px;
        margin: 0px;
    }
    .header-menu-title:last-child {
        padding: 10px 10px 10px 10px;
        margin:0px
    }

    .nav-submenu > .header-menu-title {
        padding: 20px 10px 0px 10px;
    }
    .nav-submenu > .header-menu-title:last-child {
        padding: 20px 10px 0px 10px;
    }

    
}
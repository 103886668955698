.credits {
    display:flex;
    justify-content:space-around;
    align-items:center; 
    flex-wrap:wrap;
    padding:30px;
    gap: 20px;
    margin-top: 60px;
}

@media (max-width:1024px) {

    .credits{
        justify-content: center;
        
    }
    .credits > div > img{
        margin: 50px auto !important;
    }
    
}
:root{
  --black-type: #666;
}

/* SECTION WATERSPORT CENTER */

.kite-center{
  display:flex; 
  flex-flow:row wrap;
  font-family:'Raleway';
  font-size:1.2rem; 
}

.kite-center-max-width{
max-width: 1024px;
}

.kite-center-intro{
  margin-top:0px;
}

.kite-center-text{
  max-width:440px;
  padding: 30px 0px;
  margin: 30px auto;
  line-height: 1.35;
}

.kite-center-text p{
max-width:440px;
padding: 0px 0px;
margin: 30px auto;
font-size: 20px;
line-height: 1.35;
}

@media (max-width:500px) {
.kite-center-text{
  max-width:85vw;
}
}

.kite-center-text > h2{
font-family:'Oswald'; 
font-weight:500;
font-size:2rem;
}

.kite-center-text > h3{
  font-family:'Oswald'; 
  font-weight:500;
  font-size:1.7rem;
}

.kite-center-text > h4{
font-family:'Oswald'; 
font-weight:500;
font-size:1.7rem;
}


.kite-content  p {
  line-height: 1.35;
}

@media (max-width:1366px) {
.kite-center-text > h2,
.kite-center-text > h3,
.kite-center-text > h4{
  font-size:24px;
}
.kite-center-text p{
  font-size:16px;
}
}

.kite-videos{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.kite-video{
  position: relative;
  width: 100%;
  height: 100%;
}

.kite-videos > div{
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
}

@media (max-width: 1366px) {

}

/* Responsive layout - makes a one column layout instead of a two-column layout */


.video-background {
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  /* width: auto;
  height: auto; */
  
  padding: 0px 2px;
  margin:0px;

  min-width: 100px;
  min-height: 200px;
}

.video-background > .iframe,
.video-background > .img {
  position: absolute;
  /* top: 50%;
  left: 50%; */
  top:0;
  left: 0;
  width: 100%;
  height: auto;
  /* right:0; */
  /* width:50vw;
  height: 50vh; */
  /* transform: translate(-50%, -50%); */
  border: 2px solid red
}

@media (max-width: 800px) {
  .kite-videos{
      flex-direction: column;
  }
  .kite-videos > div{
    flex: 100%;
  }
}


@media (max-aspect-ratio: 16/9) {
  .video-background iframe {
    
    /* width: 177.78vh; */
    /* height: 56.25vw; */
  }
}

@media (max-width: 800px) {
  .video-background iframe {
    
      /* width: 177.78vh; */
      /* height: 56.25vw; */
    }
}

/* Photo Grid */

.ph-row {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
}

/* Create four equal columns that sits next to each other */
.ph-column {
  flex: 25%;
  max-width: 25%;
  padding: 0 2px;
}

.ph-column img {
  margin-top: 4px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media (max-width: 800px) {
  .ph-column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .ph-column {
    flex: 100%;
    max-width: 100%;
  }
}

/* video di background di tutto lo schermo */
.video-bg {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}
.video-fg,
.video-bg iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
.vid-info { width: 50%; padding: .5rem; }
.vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
.vid-info .acronym { display: none; }
}


/* SECTION CLASSES */
.section-content{
  padding: 20px 20px 20px 20px;
  margin: 80px auto 0px auto;
  max-width: 1920px;
  width: 100%;
}

@media (max-width:1366px) {
  .section-content{
      margin: 20px auto 0px auto;
  }
}


.section-center{
  display:flex; 
  flex-flow:row wrap;
  font-family:'Raleway';
  font-size:1.2em; 
}


.section-center-text{
  max-width:440px;
  padding: 30px 0px;
  margin: auto auto;
  line-height: 1.35;
}

@media (max-width:1366px) {
  .section-center {
      font-size:1rem; 
  }
  
  .section-center-text > h2{
      font-size: 1.3rem;
  }
  .section-center-text  p{
      font-size: 1.1rem;
  }
}

@media (max-width:1024px) {
  .section-reverse{
    /* flex-direction: column-reverse; */
    flex-flow: column-reverse nowrap;
  }
}

@media (max-width:500px) {
.section-center-text{
  max-width:85vw;
}
}

.section-center-text > h3{
  font-family:'Oswald'; 
  font-weight:500;
  font-size:30px;
}

.flex-with-img{
  flex:1 0 50%;

}

.flex-with-text{
  flex:1 0 auto;
  display: flex;
}

.flex-with-img > figure {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.flex-with-img > figure > img {
  margin: auto;
}


@media (max-width:1024px) {

  .flex-with-img{
      flex: 1 0 100%;
  }
  
}


.page-section{
    display:flex; 
    flex-flow:row wrap;
    font-family:'Raleway';
    font-size:1.2rem; 
}

.page-section-img{
    flex-direction: column;
    align-items: center;
}

.page-section-img img{
    display: block;
    margin: 0px auto;
}

.page-section-content{
    margin: auto;
    max-width: 1024px;
}

.page-section > div{
    flex: 1 0 100%;
}

.page-section-inner-content h2,
.page-section-inner-content h3,
.page-section-inner-content p{
    line-height: 1.35;
}

.page-section-inner-content h2{
    text-align: center;
    font-size: 2rem;
}

.page-section-inner-content h3{
    text-align: center;
    font-size: 1.7rem;
}

@media (max-width:800px) {
    .page-section-inner-content h2.h2-only-german-mobile{
        font-size: 1.5rem;
    }
}
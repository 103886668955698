.page-header{

    background-color:#f2f2f2;
	border-bottom: 1px solid #fafafa;
	width: 100%;
	height: auto;
    text-align: left;
    padding: 0px 0px;
    box-sizing: border-box;
}

.page-header-inner{
    width: 100%;
	max-width: 1920px;
	margin:0 auto;
	height: auto;
	padding: 20px 20px;
}

.page-header-text{
    width: 100%;
	margin:0px ;
	height: auto;
	padding: 0px;
    text-align: center;
}

.page-header-text > h1{
    color: #333;
    font-size: 2.5rem;
    font-family: 'Oswald';
    font-weight: 500;
    margin:0px ;
	padding: 0px;
    text-transform: uppercase;
}


@media (max-width:1024px) {
    .page-header-text > h1{
        font-size: 2.4rem;
    }
}


@media (max-width:800px) {
    .page-header-text > h1{
        font-size: 2rem;
    }
}
footer{

    width:100%;
    height: 100%;
    background-color: #1f1f1f;
    padding:0px;
    margin:0px;
}

footer > .container {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 140px;
    justify-content: space-between;
    font-family: 'Oswald';
    /* font-size: clamp(16px, calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320))), 22px); */
    /* line-height: calc(3rem + 0.6 * ((100vw - 320px) / 1600)); */
    /* line-height: clamp(32px, 36px, 42px); */
    /* line-height: 1.2rem; */
    font-size: 1.2rem;
}

footer  ul {
    list-style: none;
    padding: 1rem;
}

footer div li {
    font-family: 'Raleway';
    font-size: 1rem;
    color: #a1a1a1;
    height: auto;
    padding-bottom: 1.5rem;
}

footer div li:first-child {
    font-family: 'Oswald';
    font-size: 1.2rem;
    color: #f7f7f7;
    text-transform: uppercase;
    height: auto;
    padding-bottom: 2rem;
}

footer div li > a {
    color: inherit;
    text-decoration: none;
}

.footer-social-img{
    width: 50px;
    height: 50px;
    padding: 0px;
    margin: 0px 10px 0px 0px;
}

.subfooter {
    color: #a1a1a1;
    width: auto;
    grid-column: 1 / 5;
    justify-self: center;
}

.subfooter > p{
    text-align: center;
    line-height: 1.35;
}

.subfooter .legal-links{
    color: inherit;
    text-decoration: none;
    padding: 10px 10px 0px 10px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sub-follow-us{
    display: none;
}

@media screen and (max-width:1024px){
    footer > .container {
        
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: auto;
    }
/*     
    .subfooter {
        grid-column: 1 / 3;
    }

    .sub-follow-us{
        display: block;
    }

    .follow-us{
        display: none;
    } */
}

@media screen and (max-width:900px){
    footer > .container {
        
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }
    .subfooter {
        grid-column: 1 / 3;
    }
}


@media screen and (max-width:540px){
    footer > .container {
        
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    footer div li {
        font-size: 1.1rem;
    }
    .subfooter {
        grid-column: 1 / 2;
    }
}

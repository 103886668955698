.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-body {
    font-family: 'Raleway';
    position: absolute;
    left: 25%;
    right: 25%;
    bottom: auto;
    top: 30%;
    padding-bottom: 30px;
    text-align: center;
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popup-body-button{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    padding-top: 0px;
    margin-top: -40px;
    border: solid 1px black;
    background-color: black;
}

.popup-body-children {
    padding: 20px 40px 40px 40px;
    font-size: 18px;
    text-align: left;
    max-height: 400px;
}


@media (max-aspect-ratio: 1/1) and (max-width:968px){
    .popup-body {
        left: 10%;
        right: 10%;
    }

    .popup-body-children {
        padding: 20px 20px 40px 20px;
    }
}

@media (max-aspect-ratio: 1/1) and (max-width:768px){
    .popup-body {
        left: 15px;
        right: 15px;
    }

    .popup-body-children {
        padding: 20px 20px 40px 20px;
    }
}
.btn-read-more{
    /* background-color: #2ab6cc; */
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #222;
    min-width: 8em;
    min-height: 3em;
    font-size: 1.2em;
    font-weight: 800;
    font-family: 'Raleway';
    text-align: center;
    padding: 1px 6px;
    align-items: flex-start;
    text-decoration: none;
    margin:auto auto;
    width: fit-content;
    color: #2ab6cc;
    border: 2px solid #2ab6cc;

    color: var(--black-type);
    border: 2px solid var(--black-type);

    display: flex;
   align-items: center;
   justify-content: space-between;
}
.btn-read-more > span{
    color: #2ab6cc;
    color: var(--black-type);
}

.btn-read-more:hover{
    background-color: #2ab6cc;
    /* border: 2px solid #777; */
    border: 2px solid #2ab6cc;
    color:#fff;
    cursor: pointer;
}

.btn-read-more:hover > span{
    color:#fff;
}
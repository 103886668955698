.price-container{
    width:100%;
    max-width:1280px;
    max-width:800px;
    margin:auto;
}

.price-main-section{
    height:100%;
    padding:0;
    text-align: center;

    /* new */
    display:flex;
    align-items:center;
     justify-content:space-between;
     flex-direction:column

}

.price-main-section > h1{
    font-family: 'Oswald';
    text-align: left;
    color:#f7a445;
    font-size: 30px;
}

.price-main-section > h2{
    font-family: 'Raleway';
    text-align: left;
}

.price-main-section > h3{
    font-family: 'Raleway';
    text-align: left;
}

.pricelist{

    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(3, 100px); */
    grid-auto-rows: auto;
    /*  */
    background-color: #fff;
    row-gap: 2px;
    column-gap: 2px;
    font-family: 'Raleway';
}


.pricelist > div{
    background-color: #eee;
    color: #333;
    font-family: 'Raleway';
}


.pricelist > div:first-child,
.pricelist > div:nth-child(2){
    background-color: #2ab6cc;
    color: #fff;
    font-family: 'Raleway';
}
.pricelist > div.no-equip,
.pricelist > div.warning{
    background-color: rgb(255, 242, 121);
    color: #333;
    font-family: 'Raleway';
    grid-column-start: 1;
    grid-column-end: 3;
}

.pricelist > div.warning-bold{
    font-weight: bold;
}

.pricelist > div.yes-equip,
.pricelist > div.info{
    background-color: #ddd;
    color: #333;
    font-family: 'Raleway';
    grid-column-start: 1;
    grid-column-end: 3;
}


.pricelist > div.pre-pay, 
.pricelist > div.book-by-email,
.pricelist > div.book-info,
.pricelist > div.terms-info
{
    
    color: #333;
    font-family: 'Raleway';
    grid-column-start: 1;
    grid-column-end: 3;
    border-radius: 5px;
    padding: 10px;
}

.pricelist > div.pre-pay{
    background-color: rgb(248, 182, 129);
    color:rgb(119, 15, 36);
    /* border: 1px solid rgb(119, 15, 36); */
    font-weight: bold;
}

.pricelist > div.book-by-email{
    background-color: rgb(173, 247, 179);
    font-weight: bold;
    /* border: 1px solid rgb(52, 92, 55); */
}

.pricelist > div.book-info{
    background-color: #eee;
    font-weight: bold;
    border: 1px solid #ddd;
    /* cursor: pointer; */
}

.pricelist > div.terms-info{
    background-color: #fff;
    /* font-weight: bold; */
    border: 1px solid #eee;
    /* cursor: pointer; */
}

.pricelist > div.pre-pay > span, 
.pricelist > div.book-by-email > span,
.pricelist > div.book-info span,
.pricelist > div.terms-info > span
{
    
    display: inline-block;
    vertical-align: middle;
}

.pricelist > div.book-by-email a{
    color: blue;
}
.pricelist > div.book-info img{
    display: inline-block;
    vertical-align: middle;
}

.pricelist > div.book-info a{
    text-decoration: none;
    color:#333;
}

.run-ani-on-header{
    animation: pulseHeader 1s ease
}
.run-ani-on{
    animation: pulse 1s ease
}


@keyframes pulseHeader {
    0% {
       background-color: #2ab6cc;
       opacity:1
   }
   50% {
    background-color: hsl(188, 36%, 64%);
    opacity:0.3
   }
   100% {
     background-color: #2ab6cc;
     opacity:1
    }
 }

 @keyframes pulse {
    0% {
       background-color: #eee;
       opacity:1
   }
   50% {
    background-color: #fff;
    opacity:0.3
   }
   100% {
     background-color: #eee;
     opacity:1
    }
 }
